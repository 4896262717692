<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import safeAreaInsets from 'safe-area-insets';
import { isNativeApplication } from '@/helpers/detection-helpers';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { useAppStore } from '@/application/common/app/store';
import HealthPointsBadge from '@/private/user/user-progress/components/health-points-badge.vue';
import { Feature } from '@/types';

@Component({
  components: {
    HealthPointsBadge,
  },
})
export default class AppHeader extends Vue {

  readonly authenticationStore = useAuthenticationStore();
  readonly appStore = useAppStore();

  readonly isNativeApplication = isNativeApplication;

  navigationDrawerVisible = false;
  toolbarHeight = 60;
  navigationDrawerWidth = 230;

  get title(): string {
    return this.authenticationStore.wasInitialAuthenticationAttempted
      ? this.appStore.titleWithFallback
      : 'Anmeldung wird überprüft';
  }

  get isLoginVisible(): boolean {
    return !this.authenticationStore.isAuthenticated;
  }

  get isAboutNubaliVisible(): boolean {
    return !this.authenticationStore.isAuthenticated
      && this.authenticationStore.isFeatureEnabled(Feature.ABOUT);
  }

  get isUnauthorizedContactVisible(): boolean {
    return !this.authenticationStore.isAuthenticated;
  }

  get isDashboardVisible(): boolean {
    return this.authenticationStore.isNormalUser;
  }

  get isModuleListVisible(): boolean {
    return this.authenticationStore.isNormalUser;
  }

  get isBlogArticleListVisible(): boolean {
    return this.authenticationStore.isNormalUser
      && this.authenticationStore.isFeatureEnabled(Feature.BLOG);
  }

  get isHabitIntentListVisible(): boolean {
    return this.authenticationStore.isNormalUser;
  }

  get isUserGoalListVisible(): boolean {
    return this.authenticationStore.isNormalUser;
  }

  get isNutritionDiaryVisible(): boolean {
    return this.authenticationStore.isNormalUser
      && this.authenticationStore.isFeatureEnabled(Feature.NUTRITION_DIARY);
  }

  get isExperienceReportsVisible(): boolean {
    return this.authenticationStore.isNormalUser
      && this.authenticationStore.isFeatureEnabled(Feature.EXPERIENCE_REPORTS);
  }

  get isRecipesVisible(): boolean {
    return this.authenticationStore.isNormalUser
      && this.authenticationStore.isFeatureEnabled(Feature.RECIPES);
  }

  get isBibliographyListVisible(): boolean {
    return this.authenticationStore.isNormalUser
      && this.authenticationStore.isFeatureEnabled(Feature.BIBLIOGRAPHY);
  }

  get isDocumentsVisible(): boolean {
    return this.authenticationStore.isNormalUser
      && this.authenticationStore.isFeatureEnabled(Feature.DOCUMENTS);
  }

  get isProfileVisible(): boolean {
    return this.authenticationStore.isNormalUser;
  }

  get isSupportVisible(): boolean {
    return this.authenticationStore.isNormalUser;
  }

  get isManagementVisible(): boolean {
    return this.authenticationStore.isAppManager;
  }

  get isAppManagerDashboardVisible(): boolean {
    return this.authenticationStore.isAppManager;
  }

  get isUserInvitesVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.INVITES);
  }

  get isCertificateManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.CERTIFICATE_MANAGEMENT);
  }

  get isModuleManagementVisible(): boolean {
    return this.authenticationStore.isAppManager;
  }

  get isHabitManagementVisible(): boolean {
    return this.authenticationStore.isAppManager;
  }

  get isBibliographyManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.BIBLIOGRAPHY);
  }

  get isModuleManagementDisabled(): boolean {
    return this.authenticationStore.courseConfiguration === null
      || !this.authenticationStore.courseConfiguration.areContentChangesAllowed;
  }

  get isHabitManagementDisabled(): boolean {
    return this.authenticationStore.courseConfiguration === null
      || !this.authenticationStore.courseConfiguration.areContentChangesAllowed;
  }

  get isBibliographyManagementDisabled(): boolean {
    return this.authenticationStore.courseConfiguration === null
      || !this.authenticationStore.courseConfiguration.areContentChangesAllowed;
  }

  get isBlogManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.BLOG);
  }

  get isManageDocumentsVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.DOCUMENTS);
  }

  get isManageSponsorsVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.SPONSORS);
  }

  get isManageLicensesAsSponsorVisible(): boolean {
    return this.authenticationStore.isSponsorManager
      && this.authenticationStore.isFeatureEnabled(Feature.SPONSORS);
  }

  get isExperienceReportsManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.EXPERIENCE_REPORTS);
  }

  get isRecipesManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.RECIPES);
  }

  get isNutritionDiaryManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.NUTRITION_DIARY);
  }

  get isGoalTrackingManagementVisible(): boolean {
    return this.authenticationStore.isAppManager
      && this.authenticationStore.isFeatureEnabled(Feature.GOAL_TRACKING);
  }

  get isHealthPointsBadgeVisible(): boolean {
    return this.authenticationStore.isAuthenticated
      && this.authenticationStore.isNormalUser;
  }

  mounted() {
    this.calculateSafeAreas();

    safeAreaInsets.onChange(() => this.calculateSafeAreas());

    watch(() => this.$route.path, () => {
      this.appStore.updateTitle(this.$route.meta?.title ?? null);
    }, { immediate: true });
  }

  calculateSafeAreas(): void {
    this.toolbarHeight = 60 + safeAreaInsets.top;
    this.navigationDrawerWidth = 230 + safeAreaInsets.left;
  }

}
</script>
<template>
<div class="header-component whitelabel d-print-none">
  <v-app-bar
    app
    flat
    dense
    light
    :height="toolbarHeight"
  >
    <v-app-bar-nav-icon
      @click="navigationDrawerVisible = !navigationDrawerVisible"
      :disabled="!authenticationStore.wasInitialAuthenticationAttempted"
    />

    <v-toolbar-title>
      {{ title }}
    </v-toolbar-title>

    <health-points-badge v-if="isHealthPointsBadgeVisible" />
  </v-app-bar>

  <v-navigation-drawer
    app
    v-model="navigationDrawerVisible"
    :width="navigationDrawerWidth"
    :temporary="true"
    :hide-overlay="true"
  >
    <v-list dense>

      <v-list-item link :to="{ name: 'authentication-login' }" v-if="isLoginVisible">
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'sign-in']" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Anmeldung</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'about-nubali' }" v-if="isAboutNubaliVisible">
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'building']" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Über Nubali</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'unauthorized-contact-contact-introduction' }" v-if="isUnauthorizedContactVisible">
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'headset']" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Technischer Support</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="authenticationStore.isDomainVisible">

        <v-list-item link :to="{ name: 'dashboard-dashboard-list' }" v-if="isDashboardVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'signs-post']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Übersicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'consume-modules-module-list' }" v-if="isModuleListVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'graduation-cap']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Module</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'consume-blog-articles-blog-article-list' }" v-if="isBlogArticleListVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'newspaper']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Blog</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'manage-habit-intents-habit-intent-list' }" v-if="isHabitIntentListVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'alarm-clock']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gewohnheiten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'user-progress-user-goal-list' }" v-if="isUserGoalListVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'chart-line']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Fortschritt</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'nutrition-diary-nutrition-diary-timeline' }" v-if="isNutritionDiaryVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'book-medical']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ernährungstagebuch</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'consume-documents-document-list' }" v-if="isDocumentsVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'file']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dokumente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'consume-experience-reports-experience-report-list' }"
          v-if="isExperienceReportsVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'book-user']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Erfahrungsberichte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'consume-recipes-recipe-list' }"
          v-if="isRecipesVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'hat-chef']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Rezepte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'bibliography-bibliography-list' }" v-if="isBibliographyListVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'books']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gesundheitsinfos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'profile-show-profile' }" v-if="isProfileVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'user']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'contact-support-support-introduction' }" v-if="isSupportVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'headset']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Support</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </template>

      <template v-if="isManagementVisible">

        <v-list-item link :to="{ name: 'app-manager-dashboard' }" v-if="isAppManagerDashboardVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'boxes']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Übersicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'user-management-user-invites' }" v-if="isUserInvitesVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'paper-plane']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Einladungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'certificate-management-certificate-list' }" v-if="isCertificateManagementVisible">
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'file-certificate']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bescheinigungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-blog-blog-article-list' }"
          v-if="isBlogManagementVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'newspaper']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Blog</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-documents-document-list' }"
          v-if="isManageDocumentsVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'file']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dokumente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-sponsors-sponsor-list' }"
          v-if="isManageSponsorsVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'building']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sponsoren</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-seats-as-sponsor' }"
          v-if="isManageLicensesAsSponsorVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'building']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Lizenzen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-experience-reports-experience-report-list' }"
          v-if="isExperienceReportsManagementVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'book-user']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Erfahrungsberichte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-recipes-recipe-list' }"
          v-if="isRecipesManagementVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'hat-chef']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Rezepte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-nutrition-diary-tip-list' }"
          v-if="isNutritionDiaryManagementVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'book-medical']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ernährungstagebuch</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'goal-tracking-management' }"
          v-if="isGoalTrackingManagementVisible"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'bullseye-arrow']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Zielvorschläge</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-modules-module-list' }"
          v-if="isModuleManagementVisible"
          :disabled="isModuleManagementDisabled"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'clipboard-list']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Module</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-habits-habit-list' }"
          v-if="isHabitManagementVisible"
          :disabled="isHabitManagementDisabled"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'alarm-clock']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gewohnheiten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{ name: 'manage-bibliography-publication-list' }"
          v-if="isBibliographyManagementVisible"
          :disabled="isBibliographyManagementDisabled"
        >
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'books']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Literaturliste</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </template>

      <v-list-group no-action>
        <template #activator>
          <v-list-item-icon>
            <font-awesome-icon :icon="['far', 'info-circle']" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Informationen</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item link :to="{ name: 'legal-terms-of-use' }">
          <v-list-item-content>
            <v-list-item-title>Nutzungsbedingungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'legal-data-protection' }">
          <v-list-item-content>
            <v-list-item-title>Datenschutz</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'legal-imprint' }">
          <v-list-item-content>
            <v-list-item-title>Impressum</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item
        link
        :to="{ name: 'logout' }"
        v-if="authenticationStore.isAuthenticated"
      >
        <v-list-item-icon>
          <font-awesome-icon :icon="['far', 'sign-out']" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Abmelden</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </v-navigation-drawer>

</div>
</template>
<style lang="sass">
.header-component.whitelabel
  .v-toolbar
    background: var(--color-white) !important

    .v-toolbar__title
      font-family: Montserrat, sans-serif !important
      letter-spacing: normal
      font-weight: 800 !important
      font-size: 15px !important
      padding-left: 0.5rem !important
      width: calc(100% - 100px)

  .v-app-bar
    border-bottom: 1px solid var(--color-grey-8)

    .v-app-bar__nav-icon
      transition: margin-left 0.4s
      margin-right: 0
      color: var(--color-grey-2)

      svg
        margin-right: 0

  .v-navigation-drawer
    top: calc(60px + env(safe-area-inset-top)) !important
    height: calc(100vh - (env(safe-area-inset-top) + 60px)) !important
    padding-left: env(safe-area-inset-left)
    box-shadow: none

    .v-list-item
      padding: 0 8px

    .v-list-item__icon:first-child
      margin-right: 1rem !important

    .v-list-group--no-action
      > .v-list-group__items
        > .v-list-item
          padding-left: 52px !important

    .v-list-item--dense,
    .v-list--dense .v-list-item .v-list-item__icon
      font-size: 1.2rem
      padding: 2px 0

      svg
        margin: 0 auto

    .v-list-item--dense,
    .v-list--dense
      .v-list-item__title,
      .v-list-item__subtitle
        font-weight: 400 !important

    .v-navigation-drawer--is-mobile,
    .v-navigation-drawer--temporary
      &:not(.v-navigation-drawer--close)
        box-shadow: none

    .v-list

      .v-list-item__icon
        margin-left: 6px

      .v-list-item
        &.v-list-item--active
          .v-list-item__content,
          .v-list-item__icon svg
            color: var(--color-white)

          &.v-list-group__header
            border-right: 1px solid var(--color-grey-9)

            .v-list-item__icon svg
              color: var(--color-brand-5)

            .v-list-item__content
              color: var(--color-grey-2)

            &:before
              background: var(--color-white)

          &:before
            background-color: var(--color-brand-5)
            opacity: 1
            z-index: 1

          .v-list-item__icon,
          .v-list-item__content
            z-index: 10

        &.v-list-item--disabled
          .v-list-item__content
            color: var(--color-grey-6)

          .v-list-item__icon svg
            color: var(--color-grey-6)

        .v-list-group__header__append-icon
          .v-icon
            margin-right: 0.5rem

      // Badge within navigation drawer - Size independent
      .v-list-item__icon
        .v-badge
          .v-badge__wrapper
            left: -2px
            top: 3px

  .v-toolbar__content
    padding-top: env(safe-area-inset-top) !important

#app
  &.is-native
    .header-component
      .v-app-bar
        .v-app-bar__nav-icon
          margin-left: -12px !important
</style>
