import { defineStore, storeToRefs } from 'pinia';
import { wrapActionWithProgress, ActionStatus } from '@/store';
import { attachAppAndUser, attachAppAndUserToCommandWithFiles } from '@/helpers/default-parameter-helper';
import { BlogArticle, BlogArticleListItem, CreateBlogArticleCommand, CreateContentElementImageCommand, CreateContentElementImageWithDescriptionCommand, CreateContentElementRichTextCommand, CreateContentElementStrategiesCommand, CreateContentElementTipBoxCommand, DeleteContentElementCommand, GetCurrentBlogArticleQuery, MoveContentElementDownCommand, MoveContentElementUpCommand, PublishBlogArticleCommand, UnpublishBlogArticleCommand, UpdateBlogArticleCommand, UpdateContentElementImageCommand, UpdateContentElementImageWithDescriptionCommand, UpdateContentElementRichTextCommand, UpdateContentElementStrategiesCommand, UpdateContentElementTipBoxCommand } from './types';
import { createBlogArticle, createContentElementImage, createContentElementImageWithDescription, createContentElementRichText, createContentElementStrategies, createContentElementTipBox, deleteContentElement, getBlogArticles, getCurrentBlogArticle, moveContentElementDown, moveContentElementUp, publishBlogArticle, unpublishBlogArticle, updateBlogArticle, updateContentElementImage, updateContentElementImageWithDescription, updateContentElementRichText, updateContentElementStrategies, updateContentElementTipBox } from './service';

interface ManageBlogArticlesState {
  blogArticles: BlogArticleListItem[];
  currentBlogArticleId: string | null;
  currentBlogArticle: BlogArticle | null;

  getBlogArticlesStatus: ActionStatus;
  getCurrentBlogArticleStatus: ActionStatus;
  createBlogArticleStatus: ActionStatus;
  updateBlogArticleStatus: ActionStatus;
  publishBlogArticleStatus: ActionStatus;
  unpublishBlogArticleStatus: ActionStatus;
  createContentElementRichTextStatus: ActionStatus;
  updateContentElementRichTextStatus: ActionStatus;
  createContentElementTipBoxStatus: ActionStatus;
  updateContentElementTipBoxStatus: ActionStatus;
  createContentElementImageStatus: ActionStatus;
  updateContentElementImageStatus: ActionStatus;
  createContentElementImageWithDescriptionStatus: ActionStatus;
  updateContentElementImageWithDescriptionStatus: ActionStatus;
  createContentElementStrategiesStatus: ActionStatus;
  updateContentElementStrategiesStatus: ActionStatus;
  moveContentElementDownStatus: ActionStatus;
  moveContentElementUpStatus: ActionStatus;
  deleteContentElementStatus: ActionStatus;
}

function initialState(): ManageBlogArticlesState {
  return {
    blogArticles: [],
    currentBlogArticleId: null,
    currentBlogArticle: null,

    getBlogArticlesStatus: ActionStatus.None,
    getCurrentBlogArticleStatus: ActionStatus.None,
    createBlogArticleStatus: ActionStatus.None,
    updateBlogArticleStatus: ActionStatus.None,
    publishBlogArticleStatus: ActionStatus.None,
    unpublishBlogArticleStatus: ActionStatus.None,
    createContentElementRichTextStatus: ActionStatus.None,
    updateContentElementRichTextStatus: ActionStatus.None,
    createContentElementTipBoxStatus: ActionStatus.None,
    updateContentElementTipBoxStatus: ActionStatus.None,
    createContentElementImageStatus: ActionStatus.None,
    updateContentElementImageStatus: ActionStatus.None,
    createContentElementImageWithDescriptionStatus: ActionStatus.None,
    updateContentElementImageWithDescriptionStatus: ActionStatus.None,
    createContentElementStrategiesStatus: ActionStatus.None,
    updateContentElementStrategiesStatus: ActionStatus.None,
    moveContentElementDownStatus: ActionStatus.None,
    moveContentElementUpStatus: ActionStatus.None,
    deleteContentElementStatus: ActionStatus.None,
  };
}

export const useManageBlogArticlesStore = defineStore('manageBlogArticles', {
  state: (): ManageBlogArticlesState => initialState(),
  getters: {
    isGetBlogArticlesProcessing: (state: ManageBlogArticlesState): boolean =>
      state.getBlogArticlesStatus === ActionStatus.InProgress,
    isGetCurrentBlogArticleProcessing: (state: ManageBlogArticlesState): boolean =>
      state.getCurrentBlogArticleStatus === ActionStatus.InProgress,
    isCreateBlogArticleProcessing: (state: ManageBlogArticlesState): boolean =>
      state.createBlogArticleStatus === ActionStatus.InProgress,
    isUpdateBlogArticleProcessing: (state: ManageBlogArticlesState): boolean =>
      state.updateBlogArticleStatus === ActionStatus.InProgress,
    isPublishBlogArticleProcessing: (state: ManageBlogArticlesState): boolean =>
      state.publishBlogArticleStatus === ActionStatus.InProgress,
    isUnpublishBlogArticleProcessing: (state: ManageBlogArticlesState): boolean =>
      state.unpublishBlogArticleStatus === ActionStatus.InProgress,
    isCreateContentElementRichTextProcessing: (state: ManageBlogArticlesState): boolean =>
      state.createContentElementRichTextStatus === ActionStatus.InProgress,
    isUpdateContentElementRichTextProcessing: (state: ManageBlogArticlesState): boolean =>
      state.updateContentElementRichTextStatus === ActionStatus.InProgress,
    isCreateContentElementTipBoxProcessing: (state: ManageBlogArticlesState): boolean =>
      state.createContentElementTipBoxStatus === ActionStatus.InProgress,
    isUpdateContentElementTipBoxProcessing: (state: ManageBlogArticlesState): boolean =>
      state.updateContentElementTipBoxStatus === ActionStatus.InProgress,
    isCreateContentElementImageProcessing: (state: ManageBlogArticlesState): boolean =>
      state.createContentElementImageStatus === ActionStatus.InProgress,
    isUpdateContentElementImageProcessing: (state: ManageBlogArticlesState): boolean =>
      state.updateContentElementImageStatus === ActionStatus.InProgress,
    isCreateContentElementImageWithDescriptionProcessing: (state: ManageBlogArticlesState): boolean =>
      state.createContentElementImageWithDescriptionStatus === ActionStatus.InProgress,
    isUpdateContentElementImageWithDescriptionProcessing: (state: ManageBlogArticlesState): boolean =>
      state.updateContentElementImageWithDescriptionStatus === ActionStatus.InProgress,
    isCreateContentElementStrategiesProcessing: (state: ManageBlogArticlesState): boolean =>
      state.createContentElementStrategiesStatus === ActionStatus.InProgress,
    isUpdateContentElementStrategiesProcessing: (state: ManageBlogArticlesState): boolean =>
      state.updateContentElementStrategiesStatus === ActionStatus.InProgress,
    isMoveContentElementDownProcessing: (state: ManageBlogArticlesState): boolean =>
      state.moveContentElementDownStatus === ActionStatus.InProgress,
    isMoveContentElementUpProcessing: (state: ManageBlogArticlesState): boolean =>
      state.moveContentElementUpStatus === ActionStatus.InProgress,
    isDeleteContentElementProcessing: (state: ManageBlogArticlesState): boolean =>
      state.deleteContentElementStatus === ActionStatus.InProgress,
  },
  actions: {

    // -- State management

    updateCurrentBlogArticle(blogArticleId: string): Promise<void> {
      this.currentBlogArticleId = blogArticleId;

      return this.getCurrentBlogArticle();
    },

    // -- Queries

    getBlogArticles(): Promise<void> {
      const { getBlogArticlesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getBlogArticlesStatus,
        () => getBlogArticles(attachAppAndUser({}))
          .then(async (blogArticles) => {
            this.blogArticles = blogArticles;
          })
      );
    },

    getCurrentBlogArticle(): Promise<void> {
      const query: GetCurrentBlogArticleQuery = {
        blogArticleId: this.currentBlogArticleId!,
      };
      const { getCurrentBlogArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        getCurrentBlogArticleStatus,
        () => getCurrentBlogArticle(attachAppAndUser(query))
          .then(async (blogArticle) => {
            this.currentBlogArticle = blogArticle;
          })
      );
    },

    // -- Commands

    createBlogArticle(command: CreateBlogArticleCommand): Promise<void> {
      const { createBlogArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createBlogArticleStatus,
        () => createBlogArticle(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getBlogArticles())
      );
    },

    updateBlogArticle(command: UpdateBlogArticleCommand): Promise<void> {
      const { updateBlogArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateBlogArticleStatus,
        () => updateBlogArticle(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getBlogArticles())
      );
    },

    publishBlogArticle(command: PublishBlogArticleCommand): Promise<void> {
      const { publishBlogArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        publishBlogArticleStatus,
        () => publishBlogArticle(attachAppAndUser(command))
          .then(() => this.getBlogArticles())
      );
    },

    unpublishBlogArticle(command: UnpublishBlogArticleCommand): Promise<void> {
      const { unpublishBlogArticleStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        unpublishBlogArticleStatus,
        () => unpublishBlogArticle(attachAppAndUser(command))
          .then(() => this.getBlogArticles())
      );
    },

    createContentElementRichText(command: CreateContentElementRichTextCommand): Promise<void> {
      const { createContentElementRichTextStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementRichTextStatus,
        () => createContentElementRichText(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    updateContentElementRichText(command: UpdateContentElementRichTextCommand): Promise<void> {
      const { updateContentElementRichTextStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementRichTextStatus,
        () => updateContentElementRichText(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    createContentElementTipBox(command: CreateContentElementTipBoxCommand): Promise<void> {
      const { createContentElementTipBoxStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementTipBoxStatus,
        () => createContentElementTipBox(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    updateContentElementTipBox(command: UpdateContentElementTipBoxCommand): Promise<void> {
      const { updateContentElementTipBoxStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementTipBoxStatus,
        () => updateContentElementTipBox(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    createContentElementImage(command: CreateContentElementImageCommand): Promise<void> {
      const { createContentElementImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementImageStatus,
        () => createContentElementImage(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    updateContentElementImage(command: UpdateContentElementImageCommand): Promise<void> {
      const { updateContentElementImageStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementImageStatus,
        () => updateContentElementImage(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    createContentElementImageWithDescription(command: CreateContentElementImageWithDescriptionCommand): Promise<void> {
      const { createContentElementImageWithDescriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementImageWithDescriptionStatus,
        () => createContentElementImageWithDescription(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    updateContentElementImageWithDescription(command: UpdateContentElementImageWithDescriptionCommand): Promise<void> {
      const { updateContentElementImageWithDescriptionStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementImageWithDescriptionStatus,
        () => updateContentElementImageWithDescription(attachAppAndUserToCommandWithFiles(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    createContentElementStrategies(command: CreateContentElementStrategiesCommand): Promise<void> {
      const { createContentElementStrategiesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        createContentElementStrategiesStatus,
        () => createContentElementStrategies(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    updateContentElementStrategies(command: UpdateContentElementStrategiesCommand): Promise<void> {
      const { updateContentElementStrategiesStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        updateContentElementStrategiesStatus,
        () => updateContentElementStrategies(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    moveContentElementDown(command: MoveContentElementDownCommand): Promise<void> {
      const { moveContentElementDownStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveContentElementDownStatus,
        () => moveContentElementDown(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    moveContentElementUp(command: MoveContentElementUpCommand): Promise<void> {
      const { moveContentElementUpStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        moveContentElementUpStatus,
        () => moveContentElementUp(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

    deleteContentElement(command: DeleteContentElementCommand): Promise<void> {
      const { deleteContentElementStatus } = storeToRefs(this);
      return wrapActionWithProgress(
        deleteContentElementStatus,
        () => deleteContentElement(attachAppAndUser(command))
          .then(() => this.getCurrentBlogArticle())
      );
    },

  },
});
