<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues } from '@/components/form';
import { useManageBlogArticlesStore } from '../store';
import { BlogArticleContentElementStrategies, UpdateContentElementStrategiesCommand } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  strategies: FormControl<string[]>;
}

@Component
export default class UpdateStrategiesDialog extends Vue {

  readonly store = useManageBlogArticlesStore();

  @Prop({ type: Object, required: true })
  readonly contentElement!: BlogArticleContentElementStrategies;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: this.contentElement.title,
          isRequired: true,
        },
        strategies: {
          label: 'Strategien',
          value: this.contentElement.strategies,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: UpdateContentElementStrategiesCommand = {
      blogArticleContentElementId: this.contentElement.blogArticleContentElementId,
      title: formValues.title!,
      strategies: formValues.strategies!,
    };

    this.store.updateContentElementStrategies(command)
      .then(() => showSuccessMessage('Strategien wurde angepasst.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isUpdateContentElementStrategiesProcessing"
>
  <template #activator="{ on }">
    <span v-on="on">
      <slot />
    </span>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Strategien anpassen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-strategies-form-control :form-control="form.controls.strategies" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isUpdateContentElementStrategiesProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isUpdateContentElementStrategiesProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Anpassen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
