<script lang="ts">
import { watch } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { useBreadcrumbStore } from '@/application/common/breadcrumb/store';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { downloadFile } from '@/helpers/file-download-helper';
import { Feature } from '@/types';
import CreateModulePageDialog from './create-module-page-dialog.vue';
import EditModulePageDialog from './edit-module-page-dialog.vue';
import CreateRichTextDialog from './create-rich-text-dialog.vue';
import EditRichTextDialog from './edit-rich-text-dialog.vue';
import CreateVideoDialog from './create-video-dialog.vue';
import EditVideoDialog from './edit-video-dialog.vue';
import CreateImageDialog from './create-image-dialog.vue';
import EditImageDialog from './edit-image-dialog.vue';
import CreateQuizDialog from './create-quiz-dialog.vue';
import UpdateQuizDialog from './update-quiz-dialog.vue';
import CreateHabitProposalDialog from './create-habit-proposal-dialog.vue';
import EditHabitProposalDialog from './edit-habit-proposal-dialog.vue';
import DeleteContentElementDialog from './delete-content-element-dialog.vue';
import CreateAcknowledgementDialog from './create-acknowledgement-dialog.vue';
import EditAcknowledgementDialog from './edit-acknowledgement-dialog.vue';
import CreateDocumentDialog from './create-document-dialog.vue';
import UpdateDocumentDialog from './update-document-dialog.vue';
import CreateSelfReflectionDialog from './create-self-reflection-dialog.vue';
import UpdateSelfReflectionDialog from './update-self-reflection-dialog.vue';
import ModulePagePreview from './module-page-preview.vue';
import { useManageModulesStore } from '../store';
import { ContentElement, DisableModulePageCommand, EnableModulePageCommand, ModulePage, MoveContentElementDownCommand, MoveContentElementUpCommand, MoveModulePageDownCommand, MoveModulePageUpCommand } from '../types';

@Component({
  components: {
    CreateModulePageDialog,
    EditModulePageDialog,
    CreateRichTextDialog,
    EditRichTextDialog,
    CreateVideoDialog,
    EditVideoDialog,
    CreateImageDialog,
    EditImageDialog,
    CreateQuizDialog,
    UpdateQuizDialog,
    CreateHabitProposalDialog,
    EditHabitProposalDialog,
    DeleteContentElementDialog,
    CreateAcknowledgementDialog,
    EditAcknowledgementDialog,
    CreateDocumentDialog,
    UpdateDocumentDialog,
    CreateSelfReflectionDialog,
    UpdateSelfReflectionDialog,
    ModulePagePreview,
  },
})
export default class ContentManagement extends Vue {

  readonly store = useManageModulesStore();
  readonly breadcrumbStore = useBreadcrumbStore();
  readonly authenticationStore = useAuthenticationStore();

  get isLoading(): boolean {
    return this.store.isGetCurrentModuleProcessing
      || this.store.isGetModuleSummaryPDFProcessing
      || this.store.isCreateModulePageProcessing
      || this.store.isUpdateModulePageProcessing
      || this.store.isMoveModulePageUpProcessing
      || this.store.isMoveModulePageDownProcessing
      || this.store.isEnableModulePageProcessing
      || this.store.isDisableModulePageProcessing
      || this.store.isCreateContentElementRichTextProcessing
      || this.store.isUpdateContentElementRichTextProcessing
      || this.store.isCreateContentElementVideoProcessing
      || this.store.isUpdateContentElementVideoProcessing
      || this.store.isCreateContentElementImageProcessing
      || this.store.isUpdateContentElementImageProcessing
      || this.store.isCreateContentElementQuizProcessing
      || this.store.isUpdateContentElementQuizProcessing
      || this.store.isCreateContentElementHabitProposalProcessing
      || this.store.isUpdateContentElementHabitProposalProcessing
      || this.store.isCreateContentElementAcknowledgementProcessing
      || this.store.isUpdateContentElementAcknowledgementProcessing
      || this.store.isMoveContentElementUpProcessing
      || this.store.isMoveContentElementDownProcessing
      || this.store.isDeleteContentElementProcessing
      || this.store.isCreateContentElementDocumentProcessing
      || this.store.isUpdateContentElementDocumentProcessing
      || this.store.isCreateContentElementSelfReflectionProcessing
      || this.store.isUpdateContentElementSelfReflectionProcessing;
  }

  get isModulePageMoveInProgress(): boolean {
    return this.store.isMoveModulePageUpProcessing
      || this.store.isMoveModulePageDownProcessing;
  }

  get isContentElementMoveInProgress(): boolean {
    return this.store.isMoveContentElementUpProcessing
      || this.store.isMoveContentElementDownProcessing;
  }

  get isCreateDocumentVisible(): boolean {
    return this.authenticationStore.isFeatureEnabled(Feature.DOCUMENTS);
  }

  mounted(): void {
    watch(() => this.store.currentModule, () => {
      const breadcrumbTitle = this.store.currentModule
        ? `Inhalte für "${this.store.currentModule.number}. ${this.store.currentModule.title}" verwalten`
        : 'Inhalte verwalten';

      this.breadcrumbStore.updateItems([
        ...this.$route.meta!.breadcrumbs!,
        { text: breadcrumbTitle },
      ]);
    }, { immediate: true });

    watch(() => this.$route.path, () => {
      this.store.updateCurrentModule(this.$route.params.id)
        .catch((error) => showErrorResponse(error));
    }, { immediate: true });
  }

  destroyed(): void {
    this.store.resetCurrentModule()
      .catch((error) => showErrorResponse(error));
  }

  isEnableModulePageShown(modulePage: ModulePage): boolean {
    return !modulePage.isEnabled;
  }

  isDisableModulePageShown(modulePage: ModulePage): boolean {
    return modulePage.isEnabled;
  }

  isModulePageMoveUpDisabled(index: number): boolean {
    return index === 0
      || this.isModulePageMoveInProgress
      || this.isLoading;
  }

  isModulePageMoveDownDisabled(index: number): boolean {
    if (!this.store.currentModule) {
      return false;
    }

    return index >= this.store.currentModule.pages.length - 1 || this.isContentElementMoveInProgress || this.isLoading;
  }

  isContentElementMoveUpDisabled(index: number): boolean {
    return index === 0 || this.isContentElementMoveInProgress || this.isLoading;
  }

  isContentElementMoveDownDisabled(modulePage: ModulePage, index: number): boolean {
    return index >= modulePage.contentElements.length - 1 || this.isModulePageMoveInProgress || this.isLoading;
  }

  onEnableModulePageClicked(modulePage: ModulePage): void {
    const command: EnableModulePageCommand = {
      modulePageId: modulePage.modulePageId,
    };

    this.store.enableModulePage(command)
      .then(() => showSuccessMessage('Seite wurde angezeigt'))
      .catch((error) => showErrorResponse(error));
  }

  onDisableModulePageClicked(modulePage: ModulePage): void {
    const command: DisableModulePageCommand = {
      modulePageId: modulePage.modulePageId,
    };

    this.store.disableModulePage(command)
      .then(() => showSuccessMessage('Seite wurde ausgeblendet'))
      .catch((error) => showErrorResponse(error));
  }

  onMoveModulePageUpClicked(modulePage: ModulePage): void {
    const command: MoveModulePageUpCommand = {
      modulePageId: modulePage.modulePageId,
    };

    this.store.moveModulePageUp(command)
      .then(() => showSuccessMessage('Seite wurde verschoben'))
      .catch((error) => showErrorResponse(error));
  }

  onMoveModulePageDownClicked(modulePage: ModulePage): void {
    const command: MoveModulePageDownCommand = {
      modulePageId: modulePage.modulePageId,
    };

    this.store.moveModulePageDown(command)
      .then(() => showSuccessMessage('Seite wurde verschoben'))
      .catch((error) => showErrorResponse(error));
  }

  onMoveContentElementUpClicked(contentElement: ContentElement, modulePage: ModulePage): void {
    const command: MoveContentElementUpCommand = {
      contentElementId: contentElement.contentElementId,
    };

    this.store.moveContentElementUp(command, modulePage.modulePageId)
      .then(() => showSuccessMessage('Inhalt wurde verschoben'))
      .catch((error) => showErrorResponse(error));
  }

  onMoveContentElementDownClicked(contentElement: ContentElement, modulePage: ModulePage): void {
    const command: MoveContentElementDownCommand = {
      contentElementId: contentElement.contentElementId,
    };

    this.store.moveContentElementDown(command, modulePage.modulePageId)
      .then(() => showSuccessMessage('Inhalt wurde verschoben'))
      .catch((error) => showErrorResponse(error));
  }

  onDownloadModuleSummaryClicked(): void {
    this.store.getModuleSummaryPDF()
      .then((fileResponse) => downloadFile(
        fileResponse.data,
        fileResponse.contentType,
        `Zusammenfassung Modul ${this.store.currentModule!.number}.pdf`
      ))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <create-module-page-dialog
    v-if="store.currentModule"
    :module="store.currentModule"
  >
    <a-trigger-primary-process-button>
      Seite erstellen
    </a-trigger-primary-process-button>
  </create-module-page-dialog>

  <div class="management">
    <div class="content-elements">

      <v-card outlined :loading="isLoading">
        <v-progress-linear v-if="store.currentModule === null" color="primary" indeterminate />
        <v-list v-if="store.currentModule" class="pt-0">
          <v-subheader class="d-flex justify-space-between">
            <span>{{ store.currentModule.number }}. {{ store.currentModule.title }}</span>
            <icon-button
              small
              :icon="['far', 'download']"
              tooltip="Zusammenfassung herunterladen"
              @click="onDownloadModuleSummaryClicked"
            />
          </v-subheader>
          <v-list-item v-if="store.currentModule.pages.length === 0" disabled :ripple="false">
            <v-list-item-icon>
              <font-awesome-icon :icon="['far', 'file']" class="ml-4 mr-0" />
            </v-list-item-icon>
            <v-list-item-content>
              Noch keine Seiten angelegt
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mb-2" v-else />

          <template v-for="(modulePage, modulePageIndex) in store.currentModule.pages">

            <v-list-item
              :key="modulePage.modulePageId"
              class="px-2"
              :class="{ 'module-page-disabled': !modulePage.isEnabled }"
              :ripple="false"
            >
              <v-list-item-icon class="mr-4">
                <font-awesome-icon :icon="['far', 'file']" class="ml-4 mr-0" />
              </v-list-item-icon>
              <v-list-item-content>
                <span class="page-title">{{ modulePage.title }}</span>
              </v-list-item-content>
              <v-list-item-icon class="mr-2 mt-2 mb-0">
                <icon-menu small :icon="['far', 'plus']">
                  <create-rich-text-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Text hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-rich-text-dialog>
                  <create-video-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Video hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-video-dialog>
                  <create-image-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Bild hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-image-dialog>
                  <create-quiz-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Quiz hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-quiz-dialog>
                  <create-habit-proposal-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Gewohnheit hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-habit-proposal-dialog>
                  <create-acknowledgement-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Bestätigung hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-acknowledgement-dialog>
                  <create-document-dialog :module-page="modulePage" v-if="isCreateDocumentVisible">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Dokument hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-document-dialog>
                  <create-self-reflection-dialog :module-page="modulePage">
                    <v-list-item @click="$root.$emit('close-icon-menu')">
                      <v-list-item-title>Selbstreflexion hinzufügen</v-list-item-title>
                    </v-list-item>
                  </create-self-reflection-dialog>
                </icon-menu>
                <icon-button
                  small
                  :icon="['far', 'eye']"
                  tooltip="Anzeigen"
                  v-if="isEnableModulePageShown(modulePage)"
                  @click="onEnableModulePageClicked(modulePage)"
                />
                <icon-button
                  small
                  :icon="['far', 'eye-slash']"
                  tooltip="Ausblenden"
                  v-if="isDisableModulePageShown(modulePage)"
                  @click="onDisableModulePageClicked(modulePage)"
                />
                <icon-button
                  small
                  :icon="['far', 'arrow-up']"
                  :disabled="isModulePageMoveUpDisabled(modulePageIndex)"
                  tooltip="Nach oben schieben"
                  disabled-tooltip="Bereits erstes Element"
                  @click="onMoveModulePageUpClicked(modulePage)"
                />
                <icon-button
                  small
                  :icon="['far', 'arrow-down']"
                  :disabled="isModulePageMoveDownDisabled(modulePageIndex)"
                  tooltip="Nach unten schieben"
                  disabled-tooltip="Bereits letztes Element"
                  @click="onMoveModulePageDownClicked(modulePage)"
                />
                <edit-module-page-dialog :module-page="modulePage">
                  <icon-button small :icon="['far', 'pencil']" tooltip="Seite anpassen" />
                </edit-module-page-dialog>
                <icon-button small :icon="['far', 'times']" disabled-tooltip="Seite entfernen" disabled />
              </v-list-item-icon>
            </v-list-item>

            <v-list-item
              v-for="(contentElement, contentPageIndex) in modulePage.contentElements"
              :key="`${contentElement.contentElementId}-item`"
              class="ml-4 px-2"
              :ripple="false"
            >

              <template v-if="contentElement.type === 'rich-text'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'align-left']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben"
                    disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben"
                    disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <edit-rich-text-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Text anpassen" />
                  </edit-rich-text-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Text löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'video'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'film']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben"
                    disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben"
                    disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <edit-video-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Video anpassen" />
                  </edit-video-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Video löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'image'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'image']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben" disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben" disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <edit-image-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Bild anpassen" />
                  </edit-image-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Bild löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'quiz'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'ballot-check']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben" disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben" disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <update-quiz-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Quiz anpassen" />
                  </update-quiz-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Quiz löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'habit-proposal'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'alarm-clock']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben"
                    disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben"
                    disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <edit-habit-proposal-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Gewohnheit anpassen" />
                  </edit-habit-proposal-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Gewohnheit löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'acknowledgement'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'clipboard-list-check']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben"
                    disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben"
                    disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <edit-acknowledgement-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Bestätigung anpassen" />
                  </edit-acknowledgement-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Bestätigung löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'document'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'file']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben"
                    disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben"
                    disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <update-document-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Dokument anpassen" />
                  </update-document-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Dokument löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

              <template v-if="contentElement.type === 'self-reflection'">
                <v-list-item-icon class="mr-4">
                  <font-awesome-icon :icon="['far', 'head-side-brain']" class="ml-4 mr-0" />
                </v-list-item-icon>
                <v-list-item-content>
                  {{ contentElement.title }}
                </v-list-item-content>
                <v-list-item-icon class="mr-2 mt-2 mb-0">
                  <icon-button
                    small
                    :icon="['far', 'arrow-up']"
                    :disabled="isContentElementMoveUpDisabled(contentPageIndex)"
                    tooltip="Nach oben schieben"
                    disabled-tooltip="Bereits erstes Element"
                    @click="onMoveContentElementUpClicked(contentElement, modulePage)" />
                  <icon-button
                    small
                    :icon="['far', 'arrow-down']"
                    :disabled="isContentElementMoveDownDisabled(modulePage, contentPageIndex)"
                    tooltip="Nach unten schieben"
                    disabled-tooltip="Bereits letztes Element"
                    @click="onMoveContentElementDownClicked(contentElement, modulePage)" />
                  <update-self-reflection-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'pencil']" tooltip="Selbstreflexion anpassen" />
                  </update-self-reflection-dialog>
                  <delete-content-element-dialog
                    :content-element="contentElement"
                    :module-page="modulePage"
                  >
                    <icon-button small :icon="['far', 'times']" tooltip="Selbstreflexion löschen" />
                  </delete-content-element-dialog>
                </v-list-item-icon>
              </template>

            </v-list-item>

            <v-divider
              v-if="modulePageIndex < store.currentModule.pages.length - 1"
              class="my-2"
              :key="`${modulePage.modulePageId}-divider`"
            />

          </template>

        </v-list>
      </v-card>

    </div>
    <div class="preview">

      <module-page-preview
        v-if="store.currentModule"
        :current-module="store.currentModule"
      />

    </div>
  </div>

</v-container>
</template>
<style lang="sass" scoped>
.module-page-disabled
  .v-list-item__icon
    color: var(--color-grey-5)
  .page-title
    color: var(--color-grey-5)

.management
  display: flex
  width: 100%
  margin-top: 1rem

  .content-elements
    flex-shrink: 1
    flex-grow: 1

  .preview
    margin-left: 1rem
    flex-grow: 0
    flex-shrink: 0
    width: 390px
</style>
