<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { formatDate } from '@/filters/dates';
import { SeatStatus } from '@/helpers/data';
import { useAuthenticationStore } from '@/application/whitelabel/authentication/store';
import { DeleteRegistrationLinkForSponsorCommand, GenerateRegistrationLinkForSponsorCommand, RefreshRegistrationLinkForSponsorCommand, ResendUserInviteForSponsorCommand, RevokeSeatCommand, Seat } from '../types';
import { useManageSponsorsStore } from '../store';
import InviteUsersForSponsorDialog from './invite-users-for-sponsor-dialog.vue';
import CopyUserRegistrationLinkDialog from './copy-user-registration-link-dialog.vue';
import CopySponsorRegistrationLinkDialog from './copy-sponsor-registration-link-dialog.vue';
import LicenseDocsCard from './license-docs-card.vue';

@Component({
  components: {
    InviteUsersForSponsorDialog,
    CopyUserRegistrationLinkDialog,
    CopySponsorRegistrationLinkDialog,
    LicenseDocsCard,
  },
})
export default class ManageSeatsAsSponsor extends Vue {

  readonly store = useManageSponsorsStore();
  readonly authenticationStore = useAuthenticationStore();

  isDocumentationVisible = false;

  get isLoading(): boolean {
    return this.store.isGetSeatsForSponsorProcessing;
  }

  get isSponsorAndSeatsAvailable(): boolean {
    return this.store.sponsorSeats !== null;
  }

  get doesSponsorHaveARegistrationLink(): boolean {
    return this.store.sponsorSeats !== null
      && !!this.store.sponsorSeats.registrationLink;
  }

  get isGenerateLinkVisible(): boolean {
    return !this.doesSponsorHaveARegistrationLink;
  }

  get isCopyLinkVisible(): boolean {
    return this.doesSponsorHaveARegistrationLink;
  }

  get isRefreshLinkVisible(): boolean {
    return this.doesSponsorHaveARegistrationLink;
  }

  get isDeleteLinkVisible(): boolean {
    return this.doesSponsorHaveARegistrationLink;
  }

  get isInviteUsersForSponsorDisabled(): boolean {
    if (this.store.sponsorSeats === null) {
      return true;
    }

    return this.store.sponsorSeats.freeSeats < 1;
  }

  mounted(): void {
    this.store.updateCurrentSponsor(this.authenticationStore.user!.role.meta!.sponsorId)
      .catch((error) => showErrorResponse(error));
  }

  destroyed(): void {
    this.store.resetCurrentSponsor();
  }

  isNameVisible(seat: Seat): boolean {
    return !!seat.firstName
      && !!seat.lastName;
  }

  isAccepted(seat: Seat): boolean {
    return seat.status === SeatStatus.ACCEPTED;
  }

  updatedAt(seat: Seat): string {
    switch (seat.status) {
      case SeatStatus.INVITED:
        return `Eingeladen am ${formatDate(seat.updatedAt)}.`;
      case SeatStatus.REGISTRATION_STARTED:
        return `Registrierung gestartet am ${formatDate(seat.updatedAt)}.`;
      case SeatStatus.ACCEPTED:
        return `Registrierung abgeschlossen am ${formatDate(seat.updatedAt)}.`;
      default:
        throw new Error('Invalid seat status.');
    }
  }

  isResendUserInviteDisabled(seat: Seat): boolean {
    return seat.status === 'ACCEPTED';
  }

  isRegistrationLinkDisabled(seat: Seat): boolean {
    return !seat.registrationLink;
  }

  resendUserInviteConfirmed(seat: Seat): void {
    const command: ResendUserInviteForSponsorCommand = {
      usedSeatId: seat.usedSeatId,
    };

    this.store.resendUserInviteForSponsor(command)
      .then(() => showSuccessMessage('Die Einladung wurde erneut verschickt.'))
      .catch((error) => showErrorResponse(error));
  }

  revokeSeatConfirmed(seat: Seat): void {
    const command: RevokeSeatCommand = {
      usedSeatId: seat.usedSeatId,
    };

    this.store.revokeSeat(command)
      .then(() => showSuccessMessage('Lizenz wurde zurückgezogen.'))
      .catch((error) => showErrorResponse(error));
  }

  async generateLinkConfirmed(): Promise<void> {
    const command: GenerateRegistrationLinkForSponsorCommand = {
      sponsorId: this.store.currentSponsorId!,
    };

    return this.store.generateRegistrationLinkForSponsor(command)
      .then(() => showSuccessMessage('Registrierungslink wurde erstellt.'))
      .catch((error) => showErrorResponse(error));
  }

  async refreshLinkConfirmed(): Promise<void> {
    const command: RefreshRegistrationLinkForSponsorCommand = {
      sponsorId: this.store.currentSponsorId!,
    };

    return this.store.refreshRegistrationLinkForSponsor(command)
      .then(() => showSuccessMessage('Registrierungslink wurde aktualisiert.'))
      .catch((error) => showErrorResponse(error));
  }

  async deleteLinkConfirmed(): Promise<void> {
    const command: DeleteRegistrationLinkForSponsorCommand = {
      sponsorId: this.store.currentSponsorId!,
    };

    return this.store.deleteRegistrationLinkForSponsor(command)
      .then(() => showSuccessMessage('Registrierungslink wurde gelöscht.'))
      .catch((error) => showErrorResponse(error));
  }

}
</script>
<template>
<v-container>

  <v-progress-linear v-if="isLoading" color="primary" indeterminate />

  <template v-if="isSponsorAndSeatsAvailable">

    <div class="d-flex justify-space-between align-center mb-4">
      <div class="flex-grow-1 flex-shrink-1">
        Es sind noch <strong>{{ store.sponsorSeats.freeSeats }}</strong> von {{ store.sponsorSeats.availableSeats }} Lizenzen verfügbar.
      </div>
      <div class="flex-grow-0 flex-shrink-0">
        <v-checkbox
          v-model="isDocumentationVisible"
          label="Dokumentation anzeigen"
          hide-details
          class="mt-0 pt-0"
        />
      </div>
    </div>

    <a-action-banner class="mb-6">
      <template #content>
        <template v-if="doesSponsorHaveARegistrationLink">
          <strong class="d-block">Eine Registrierung ist über einen Link und eine explizite Einladung per E-Mail möglich.</strong>
          <span>Sie können den Registrierungslink hier kopieren, erneuern und löschen.</span>
        </template>
        <template v-else>
          <strong class="d-block">Eine Registrierung ist aktuell nur über eine explizite Einladung per E-Mail möglich.</strong>
          <span>Sie können hier einen Link generieren, über den sich jeder der diesen Link hat, selbst registrieren kann.</span>
        </template>
      </template>
      <template #actions>

        <a-confirmation-dialog
          title="Link erstellen"
          button-text="Link erstellen"
          :action="() => generateLinkConfirmed"
          :is-processing="store.isGenerateRegistrationLinkForSponsorProcessing"
        >
          <span>
            <a-trigger-primary-process-button
              v-if="isGenerateLinkVisible"
              is-small
            >
              Link erstellen
            </a-trigger-primary-process-button>
          </span>
          <template #content>
            <p>
              Möchten Sie den Link generieren? Sie können diesen dann z.B. per E-Mail mit allen Mitarbeitern teilen.
            </p>
            <p>
              Durch jede abgeschlossene Registrierung wird ein Konto erzeugt. Jedem erzeugten Konto wird automatisch eine Lizenz zugeordnet. Eine Registrierung kann nicht abgeschlossen werden, falls keine Lizenzen mehr verfügbar sind.
            </p>
            <a-warning-alert>
              Jeder der diesen Link hat, kann sich registrieren. Der Link sollte daher nicht öffentlich, sondern nur mit relevanten Personen geteilt werden.
            </a-warning-alert>
          </template>
        </a-confirmation-dialog>

        <copy-sponsor-registration-link-dialog>
          <span>
             <icon-button
               v-if="isCopyLinkVisible"
               small
               :icon="['far', 'link']"
               tooltip="Link kopieren"
             />
          </span>
        </copy-sponsor-registration-link-dialog>

        <a-confirmation-dialog
          title="Link aktualisieren"
          button-text="Link aktualisieren"
          highlight="danger"
          :action="() => refreshLinkConfirmed"
          :is-processing="store.isRefreshRegistrationLinkForSponsorProcessing"
        >
          <span>
            <icon-button
              v-if="isRefreshLinkVisible"
              small
              :icon="['far', 'rotate']"
              tooltip="Link aktualisieren"
            />
          </span>
          <template #content>
            <p>
              Möchten Sie den Link aktualisieren?
            </p>
            <p>
              Diese Aktion ist zu empfehlen, wenn ein Link an eine falsche Gruppe geschickt wurde oder aus anderen Gründen sich nur noch eine neue Gruppe registrieren soll.
            </p>
            <a-danger-alert>
              Der vorherige Link wird dadurch ungültig und Benutzer mit dem vorherigen Link können keine Registrierung mehr starten. <br />
              Benutzer die bereits eine Registrierung gestartet haben, können diese nach wie vor abschließen.
            </a-danger-alert>
          </template>
        </a-confirmation-dialog>

        <a-confirmation-dialog
          title="Link löschen"
          button-text="Löschen"
          highlight="danger"
          :action="() => deleteLinkConfirmed"
          :is-processing="store.isDeleteRegistrationLinkForSponsorProcessing"
        >
          <span>
            <icon-button
              small
              :icon="['far', 'link-slash']"
              tooltip="Link löschen"
              v-if="isDeleteLinkVisible"
            />
          </span>
          <template #content>
            <p>
              Möchten Sie den Link wirklich löschen?
            </p>
            <p>
              Diese Aktion ist zu empfehlen, wenn sich Benutzer nicht mehr über einen Link registrieren können sollen.
            </p>
            <a-danger-alert>
              Der Link wird dadurch ungültig und Benutzer mit dem Link können keine Registrierung mehr starten.<br />
              Benutzer die bereits eine Registrierung gestartet haben, können diese nach wie vor abschließen.
            </a-danger-alert>
          </template>
        </a-confirmation-dialog>

      </template>
    </a-action-banner>

    <invite-users-for-sponsor-dialog :disabled="isInviteUsersForSponsorDisabled">
      <a-trigger-primary-process-button
        :is-disabled="isInviteUsersForSponsorDisabled"
        class="mt-4"
      >
        Benutzer einladen
      </a-trigger-primary-process-button>
    </invite-users-for-sponsor-dialog>

    <v-row>
      <v-col>

        <v-card class="mt-4 mb-4" outlined>
          <a-action-list
            :items="store.sponsorSeats.seats"
            item-id="usedSeatId"
            empty-list-message="Noch keine Lizenzen vergeben."
          >
            <template #content="{ item: seat }">
              <span class="item-title">
                {{ seat.emailAddress }}
                <span v-if="isNameVisible(seat)">
                  ({{ seat.firstName }} {{ seat.lastName }})
                </span>
              </span>
              <span class="details">
                {{ updatedAt(seat) }}
              </span>
            </template>
            <template #actions="{ item: seat }">

              <copy-user-registration-link-dialog
                :registration-link="seat.registrationLink"
                :disabled="isRegistrationLinkDisabled(seat)"
              >
                <icon-button
                  small
                  :icon="['far', 'link']"
                  tooltip="Link zum Registrierungsabschluss kopieren"
                  disabled-tooltip="Registrierung wurde bereits abgeschlossen"
                  :disabled="isRegistrationLinkDisabled(seat)"
                />
              </copy-user-registration-link-dialog>

              <a-confirmation-dialog
                title="Einladung erneut verschicken"
                button-text="Verschicken"
                highlight="warning"
                :action="() => resendUserInviteConfirmed(seat)"
                :is-processing="store.isResendUserInviteForSponsorProcessing"
                :is-disabled="isResendUserInviteDisabled(seat)"
              >
                <icon-button
                  small
                  :icon="['far', 'paper-plane']"
                  tooltip="Einladung erneut versenden"
                  disabled-tooltip="Einladung bereits angenommen"
                  :disabled="isResendUserInviteDisabled(seat)"
                />
                <template #content>
                  <p>
                    Soll die Einladung wirklich erneut verschickt werden?
                  </p>
                </template>
              </a-confirmation-dialog>

              <a-confirmation-dialog
                title="Lizenz zurückziehen"
                button-text="Zurückziehen"
                highlight="danger"
                :action="() => revokeSeatConfirmed(seat)"
                :is-processing="store.isRevokeSeatProcessing"
              >
                <icon-button
                  small
                  :icon="['far', 'ban']"
                  tooltip="Lizenz zurückziehen"
                />
                <template #content>
                  <p>
                    Soll die Lizenz für <strong>{{ seat.emailAddress }}</strong> wirklich zurückgezogen werden?
                  </p>
                  <p>
                    Der Benutzer wird per E-Mail über die Stornierung informiert.
                  </p>
                  <a-danger-alert>
                    <template v-if="seat.status === 'INVITED'">
                      Die Einladung wird gelöscht und der Benutzer kann die Registrierung nicht mehr abschließen.
                    </template>
                    <template v-else>
                      Der Benutzer verliert Zugriff zur App und alle Daten des Benutzers werden unwiderruflich gelöscht.
                    </template>
                  </a-danger-alert>
                </template>
              </a-confirmation-dialog>

            </template>
          </a-action-list>
        </v-card>

      </v-col>
      <v-col v-if="isDocumentationVisible">

        <license-docs-card class="mt-4 mb-4" />

      </v-col>
    </v-row>

  </template>

</v-container>
</template>
