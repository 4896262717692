const LOCAL_STORAGE_HELPER_CHECK = 'local-storage-helper-check';

export const LOCAL_STORAGE_APP_ID = 'app-id';
export const LOCAL_STORAGE_USER_ID = 'user-id';
export const LOCAL_STORAGE_ADMIN_ID = 'admin-id';
export const LOCAL_STORAGE_IMPERSONATION_ACTIVE = 'impersonation-active';

export function isLocalStorageAvailable(): boolean {
  try {
    localStorage.setItem(LOCAL_STORAGE_HELPER_CHECK, '1');
    localStorage.removeItem(LOCAL_STORAGE_HELPER_CHECK);
    return true;
  } catch (e) {
    return false;
  }
}

export function hasAuthenticationInLocalStorage(): boolean {
  return getLocalStorageItem(LOCAL_STORAGE_USER_ID) !== null
    && getLocalStorageItem(LOCAL_STORAGE_APP_ID) !== null;
}

export function getLocalStorageItem(key: string): string|null {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // We return null as a case where local storage is not available
    return null;
  }
}

export function setLocalStorageItem(key: string, value: string): void {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    // We don't handle a case where local storage is not available
  }
}

export function removeLocalStorageItem(key: string): void {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    // We don't handle a case where local storage is not available
  }
}
