import Vue from 'vue';
import VueRouter from 'vue-router';
import { accessGuard } from '@/application/whitelabel/authentication/access-guard';
import { appVersionGuard } from '@/application/common/app-version-hint/app-version-guard';
import initMaintenanceModeInterceptor from '@/application/whitelabel/maintenance-overlay/axios-maintenance-mode-interceptor';
import initAppVersionInterceptor from '@/application/common/app-version-hint/axios-app-version-interceptor';
import { routes } from './routes';

Vue.use(VueRouter);

initMaintenanceModeInterceptor();
initAppVersionInterceptor();

export const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(accessGuard);

router.afterEach(appVersionGuard);
