import Vue from 'vue';
import VueRouter from 'vue-router';
import { accessGuard } from '@/application/admin/authentication/access-guard';
import { appVersionGuard } from '@/application/common/app-version-hint/app-version-guard';
import { routes } from './routes';

Vue.use(VueRouter);

export const router: VueRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: () => ({ x: 0, y: 0 }),
});

router.beforeEach(accessGuard);

router.afterEach(appVersionGuard);
