export type Id = string;
export type AppId = Id;
export type BlogArticleId = Id;
export type ContentElementId = Id;
export type ContentElementQuizAnswerId = Id;
export type DocumentId = Id;
export type ExperienceReportId = Id;
export type HabitIntentId = Id;
export type HabitId = Id;
export type ModulePageId = Id;
export type ModuleId = Id;
export type NutritionDiaryEntryId = Id;
export type NotificationId = Id;
export type NutritionDiaryTipId = Id;
export type RecipeId = Id;
export type SponsorId = Id;
export type UserGoalId = Id;
export type UserGoalProposalId = Id;

export type DocumentIdList = Document[];
export type ExperienceReportIdList = ExperienceReportId[];
export type NutritionDiaryTipIdList = NutritionDiaryTipId[];
export type RecipeIdList = RecipeId[];

export type EmailAddress = string;

export enum IssueType {
  'TECHNICAL' = 'TECHNICAL',
  'CONTENT' = 'CONTENT',
}

export enum Gender {
  'MALE' = 'MALE',
  'FEMALE' = 'FEMALE',
  'DIVERS' = 'DIVERS',
}

export enum AppType {
  'COURSE' = 'COURSE',
  'SPONSOR' = 'SPONSOR',
}

export enum PreventionType {
  'NUTRITION' = 'NUTRITION',
  'MOVEMENT' = 'MOVEMENT',
  'STRESS' = 'STRESS',
}

export enum Feature {
  NUTRITION_DIARY = 'NUTRITION_DIARY',
  INVITES = 'INVITES',
  SALES_DASHBOARD = 'SALES_DASHBOARD',
  BIBLIOGRAPHY = 'BIBLIOGRAPHY',
  CERTIFICATE_MANAGEMENT = 'CERTIFICATE_MANAGEMENT',
  BLOG = 'BLOG',
  EXPERIENCE_REPORTS = 'EXPERIENCE_REPORTS',
  RECIPES = 'RECIPES',
  DOCUMENTS = 'DOCUMENTS',
  SPONSORS = 'SPONSORS',
  ABOUT = 'ABOUT',
  GOAL_TRACKING = 'GOAL_TRACKING',
}

export enum Weekday {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export enum VisibleFor {
  ALWAYS = 'ALWAYS',
  APP = 'APP',
  PDF = 'PDF',
}

export enum PublicationType {
  BOOK = 'BOOK',
  ONLINE = 'ONLINE',
}

export enum IngredientUnit {
  PIECE = 'PIECE',
  GRAM = 'GRAM',
  MILLILITER = 'MILLILITER',
  TABLESPOON = 'TABLESPOON',
  TEASPOON = 'TEASPOON',
  BUNCH = 'BUNCH',
  HANDFUL = 'HANDFUL',
  TO_TASTE = 'TO_TASTE',
}

export interface SelfReflectionOption {
  answer: string;
  reaction: string;
}

export enum NotificationType {
  'MODULE_UNLOCKED' = 'MODULE_UNLOCKED',
  'HABIT_REMINDER' = 'HABIT_REMINDER',
}
export interface NotificationConfiguration {
  type: NotificationType;
  payload: NotificationPayload;
}
export interface NotificationPayload {
  context: string;
  notificationId: NotificationId;
}

export enum NutritionDiaryTipType {
  CHECK = 'CHECK',
  FACT = 'FACT',
  CHALLENGE = 'CHALLENGE',
}
