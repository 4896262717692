<script lang="ts">
import { watch } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DialogWidth } from '@/helpers/data';
import { showErrorResponse, showSuccessMessage } from '@/application/common/snackbar/service';
import { constructForm, Form, FormControl, FormControls, getFormValues, maxLengthRule } from '@/components/form';
import { VisibleFor } from '@/types';
import { useManageModulesStore } from '../store';
import { CreateContentElementRichTextCommand, ModulePage } from '../types';

interface Controls extends FormControls {
  title: FormControl<string>;
  visibleFor: FormControl<VisibleFor>;
  html: FormControl<string>;
}

@Component
export default class CreateRichTextDialog extends Vue {

  readonly store = useManageModulesStore();

  @Prop({ type: Object, required: true })
  readonly modulePage!: ModulePage;

  readonly dialogMaxWidth = DialogWidth.large;

  isDialogVisible = false;
  form: Form<Controls> | null = null;

  get isSubmitDisabled(): boolean {
    return !this.form
      || !this.form.isValid;
  }

  mounted(): void {
    watch(() => this.isDialogVisible, () => {
      this.form = this.isDialogVisible
        ? this.buildForm()
        : null;
    });
  }

  buildForm(): Form<Controls> {
    return constructForm({
      submitted: this.submitted,
      controls: {
        title: {
          label: 'Bezeichnung',
          value: null,
          isRequired: true,
          rules: [
            maxLengthRule(255),
          ],
        },
        visibleFor: {
          label: 'Sichtbar',
          value: VisibleFor.ALWAYS,
          isRequired: true,
        },
        html: {
          label: 'Text',
          value: null,
          isRequired: true,
        },
      },
    });
  }

  submitted(): void {
    const formValues = getFormValues(this.form!);

    const command: CreateContentElementRichTextCommand = {
      modulePageId: this.modulePage.modulePageId,
      contentElementTitle: formValues.title!,
      contentElementVisibleFor: formValues.visibleFor!,
      textHtml: formValues.html!,
    };

    this.store.createContentElementRichText(command)
      .then(() => showSuccessMessage('Text wurde erstellt.'))
      .then(() => this.closeDialog())
      .catch((error) => showErrorResponse(error));
  }

  closeDialog(): void {
    this.isDialogVisible = false;
  }

}
</script>
<template>
<v-dialog
  v-model="isDialogVisible"
  :max-width="dialogMaxWidth"
  :persistent="store.isCreateContentElementRichTextProcessing"
>
  <template #activator="{ on }">
    <div v-on="on">
      <slot />
    </div>
  </template>
  <v-card>
    <a-form
      v-if="form"
      :form="form"
    >
      <v-card-title>Text erstellen</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <a-text-form-control
              :form-control="form.controls.title"
              is-autofocused
            />
          </v-col>
          <v-col>
            <a-visible-for-form-control :form-control="form.controls.visibleFor" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <a-html-form-control :form-control="form.controls.html" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <a-cancel-action-button
          :is-processing="store.isCreateContentElementRichTextProcessing"
          @click="closeDialog"
        />
        <a-submit-primary-action-button
          :is-processing="store.isCreateContentElementRichTextProcessing"
          :is-disabled="isSubmitDisabled"
        >
          Erstellen
        </a-submit-primary-action-button>
      </v-card-actions>
    </a-form>
  </v-card>
</v-dialog>
</template>
